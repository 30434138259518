import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { CommonFunctionService } from "src/app/services/commonfunction.service";
import { WebService } from "src/app/services/Webservice.service";
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html'
})
export class SignupComponent {
  signupform: FormGroup;
  submitted: boolean;
  signupvalid = false;
  hide = true;
  chide = true;
  checkdisecode: boolean;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private service: WebService,
    public CommonService: CommonFunctionService
  ) { }
  ngOnInit(): void {
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
    });
    this.signupform = this.formbuilder.group({
      UDISE: ["", [Validators.required]],
      SchoolName: ["", [Validators.required]],
      Respondent_name: ["", [Validators.required]],
      Respondent_desig: ["", [Validators.required]],
      Respondent_cont: ["", [Validators.required]],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^[\w-\.\']{1,}\@([\da-zA-Z-]{1,}\.){1,}[\da-zA-Z-]{2,3}$/
          ),
        ]),
      ],
      password: ["", [Validators.required]],
      CFpassword: ["", [Validators.required]],
      blockname: [""],
      village: [""],
      districe: [""],
      state: [""],
    });
  }
  onSubmit() {
  }
  
  check_disecode() {
  }
}
