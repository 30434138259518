import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from "@angular/router";
import { SlimLoadingBarService } from "ng2-slim-loading-bar";
import { TranslateService } from "@ngx-translate/core";
import { CommonFunctionService } from "./services/commonfunction.service";
import { WebService } from "./services/Webservice.service";
declare var $: any;
@Component({
  selector: "app-root",
  template: `<ng2-slim-loading-bar></ng2-slim-loading-bar>
    <router-outlet>
      <app-spinner></app-spinner>
    </router-outlet>
 `
})
export class AppComponent implements OnInit, OnDestroy {
  private sub: any;
  constructor(
    private slimLoader: SlimLoadingBarService,
    private router: Router,
    public CommonService: CommonFunctionService,
    private translate: TranslateService,
    private service: WebService,
  ) {
    translate.setDefaultLang('en');
    this.sub = this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.slimLoader.start();
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          $("#main-wrapper").removeClass("show-sidebar");
          $(".navbar-collapse").removeClass("show");
          this.CommonService.showMobileMenu = false;
          this.slimLoader.complete();
        }
      },
      (error: any) => {
        $("#main-wrapper").removeClass("show-sidebar");
        $(".navbar-collapse").removeClass("show");
        this.CommonService.showMobileMenu = false;
        this.slimLoader.complete();
      }
    );
  }
  ngOnInit(): void {
   }
  ngOnDestroy(): any {
    this.sub.unsubscribe();
  }
}
